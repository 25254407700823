import * as i18n from 'i18next';


import mariupol_1 from '../../assets/img/slider/webp/mariupol_1.webp';
import mariupol_2 from '../../assets/img/slider/webp/mariupol_2.webp';
import mariupol_3 from '../../assets/img/slider/webp/mariupol_3.webp';
import mariupol_4 from '../../assets/img/slider/webp/mariupol_4.webp';
import mariupol_5 from '../../assets/img/slider/webp/mariupol_5.webp';

// import bucha_1 from '../../assets/img/slider/webp/bucha_1.webp';
import bucha_2 from '../../assets/img/slider/webp/bucha_2.webp';
import bucha_3 from '../../assets/img/slider/webp/bucha_3.webp';
import bucha_4 from '../../assets/img/slider/webp/bucha_4.webp';
import bucha_5 from '../../assets/img/slider/webp/bucha_5.webp';
import bucha_6 from '../../assets/img/slider/webp/bucha_6.webp';

import irpin_1 from '../../assets/img/slider/webp/irpin_1.webp';
import irpin_2 from '../../assets/img/slider/webp/irpin_2.webp';
import irpin_3 from '../../assets/img/slider/webp/irpin_3.webp';
import irpin_4 from '../../assets/img/slider/webp/irpin_4.webp';
import irpin_5 from '../../assets/img/slider/webp/irpin_5.webp';
import irpin_6 from '../../assets/img/slider/webp/irpin_6.webp';

// import kyiv_1 from '../../assets/img/slider/webp/kyiv_1.webp';
import kyiv_2 from '../../assets/img/slider/webp/kyiv_2.webp';
import kyiv_3 from '../../assets/img/slider/webp/kyiv_3.webp';
import kyiv_4 from '../../assets/img/slider/webp/kyiv_4.webp';
import kyiv_5 from '../../assets/img/slider/webp/kyiv_5.webp';

import kh_1 from '../../assets/img/slider/webp/kh_1.webp';
import kh_2 from '../../assets/img/slider/webp/kh_2.webp';
import kh_3 from '../../assets/img/slider/webp/kh_3.webp';
import kh_4 from '../../assets/img/slider/webp/kh_4.webp';
import kh_5 from '../../assets/img/slider/webp/kh_5.webp';
import kh_6 from '../../assets/img/slider/webp/kh_6.webp';

import myk_1 from '../../assets/img/slider/webp/myk_1.webp';
import myk_2 from '../../assets/img/slider/webp/myk_2.webp';
import myk_3 from '../../assets/img/slider/webp/myk_3.webp';
import myk_4 from '../../assets/img/slider/webp/myk_4.webp';
import myk_5 from '../../assets/img/slider/webp/myk_5.webp';

import odessa_1 from '../../assets/img/slider/webp/odessa_1.webp';
import odessa_2 from '../../assets/img/slider/webp/odessa_2.webp';
import odessa_3 from '../../assets/img/slider/webp/odessa_3.webp';

import chern_1 from '../../assets/img/slider/webp/chern_1.webp';
import chern_2 from '../../assets/img/slider/webp/chern_2.webp';
import chern_3 from '../../assets/img/slider/webp/chern_3.webp';
import chern_4 from '../../assets/img/slider/webp/chern_4.webp';
import chern_5 from '../../assets/img/slider/webp/chern_5.webp';
import chern_6 from '../../assets/img/slider/webp/chern_6.webp';

export const slider = [
    {
        title: i18n.t('homepage.slider.mariupol.title'),
        region: i18n.t('homepage.slider.mariupol.region'),
        img: [
            {
                photo: mariupol_1,
                by: 'Photo: azov.org.ua'
            }, 
            {
                photo: mariupol_2,
                by: 'Photo: azov.org.ua'
            }, 
            {
                photo: mariupol_3,
                by: 'Photo: azov.org.ua'
            }, 
            {
                photo: mariupol_4,
                by: 'Photo: azov.org.ua'
            }, 
            {
                photo: mariupol_5,
                by: 'Photo: azov.org.ua'
            }
        ]
    },
    {
        title: i18n.t('homepage.slider.bucha.title'),
        region: i18n.t('homepage.slider.bucha.region'),
        img: [
            // {
            //     photo: bucha_1,
            //     by: 'photo by Konstantin Liberov'
            // }, 
            {
                photo: bucha_6,
                by: 'Photo: Konstantin Liberov'
            },
            {
                photo: bucha_2,
                by: 'Photo: Konstantin Liberov'
            }, 
            {
                photo: bucha_3,
                by: 'Photo: Konstantin Liberov'
            }, 
            {
                photo: bucha_4,
                by: 'Photo: Konstantin Liberov'
            }, 
            {
                photo: bucha_5,
                by: 'Photo: Andrii Bashtovyi'
            },
        ]
    },
    {
        title: i18n.t('homepage.slider.irpin.title'),
        region: i18n.t('homepage.slider.irpin.region'),
        img: [
            {
                photo: irpin_2,
                by: 'Photo: Konstantin Liberov'
            }, 
            {
                photo: irpin_3,
                by: 'Photo: Konstantin Liberov'
            }, 
            {
                photo: irpin_4,
                by: 'Photo: Dmytro Sukhomlyn'
            }, 
            {
                photo: irpin_5,
                by: 'Photo: Konstantin Liberov'
            }, 
            {
                photo: irpin_1,
                by: 'photo by Konstantin Liberov'
            }, 
            {
                photo: irpin_6,
                by: 'Photo: Konstantin Liberov'
            }
        ],
    },
    {
        title: i18n.t('homepage.slider.kyiv.title'),
        region: i18n.t('homepage.slider.kyiv.region'),
        img: [
            // {
            //     photo: kyiv_1,
            // }, 
            {
                photo: kyiv_2,
                by: 'Photo: kyiv.media'
            }, 
            {
                photo: kyiv_3,
                by: 'Photo: Olexiy Samsonov, KMDA'
            }, 
            {
                photo: kyiv_4,
                by: 'Photo: KMDA'
            }, 
            {
                photo: kyiv_5,
                by: 'Photo: Olexiy Samsonov, KMDA'
            }
        ]
    },
    {
        title: i18n.t('homepage.slider.odesa.title'),
        region: i18n.t('homepage.slider.odesa.region'),
        img: [
            {
                photo: odessa_1,
                by: 'Photo: Konstantin Liberov'
            }, 
            {
                photo: odessa_2,
                by: 'Photo: Nina Lyashonok, Ukruiniform'
            }, 
            {
                photo: odessa_3,
                by: 'Photo: Konstantin Liberov'
            }
        ]
    },
    {
        title: i18n.t('homepage.slider.mykolaiv.title'),
        region: i18n.t('homepage.slider.mykolaiv.region'),
        img: [
            {
                photo: myk_1,
                by: 'Photo: Konstantin Liberov'
            }, 
            {
                photo: myk_2,
                by: 'Photo: Konstantin Liberov'
            }, 
            {
                photo: myk_3,
                by: 'Photo: Konstantin Liberov'
            }, 
            {
                photo: myk_4,
                by: 'Photo: Konstantin Liberov'
            }, 
            {
                photo: myk_5,
                by: 'Photo: Konstantin Liberov'
            }
        ]
    },
    {
        title: i18n.t('homepage.slider.chernihiv.title'),
        region: i18n.t('homepage.slider.chernihiv.region'),
        img: [
            {
                photo: chern_1,
                by: 'Photo: Konstantin Liberov'
            }, 
            {
                photo: chern_2,
                by: 'Photo: DSNS Ukraine'
            }, 
            {
                photo: chern_3,
                by: 'Photo: Konstantin Liberov'
            }, 
            {
                photo: chern_4,
                by: 'Photo: Konstantin Liberov'
            }, 
            {
                photo: chern_5,
                by: 'Photo: Konstantin Liberov'
            }, 
            {
                photo: chern_6,
                by: 'Photo: Konstantin Liberov'
            }
        ]
    },
    {
        title: i18n.t('homepage.slider.kharkiv.title'),
        region: i18n.t('homepage.slider.kharkiv.region'),
        img: [
            {
                photo: kh_1,
                by: 'Photo: Andrii Bashtovyi'
            },
            {
                photo: kh_2,
                by: 'Photo: DSNS Ukraine'
            }, 
            {
                photo: kh_3,
                by: 'Photo: Konstantin Liberov'
            }, 
            {
                photo: kh_4,
                by: 'Photo: Konstantin Liberov'
            }, 
            {
                photo: kh_5,
                by: 'Photo: Konstantin Liberov',
            }, 
            {
                photo: kh_6,
                by: 'Photo: Konstantin Liberov'        
            }
        ]
    },  
];
