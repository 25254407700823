import Tag from "../../components/Tag";
import * as i18n from "i18next";
import { Interweave } from "interweave";

const WarText = () => {
    return (
        <section className="app-war-text" id="war">
            <div className="container">
                <div className="col-lg-8">
                    <Tag color={'white'} text={i18n.t('homepage.war.tag')}/>
                    <Interweave containerTagName="h3" content={i18n.t('homepage.war.text')} />
                </div>
            </div>
        </section>
    )
}

export default WarText;