import { useEffect, useState } from 'react';

export const useIsVisible = (ref) => {
    // let initial = 1;
    const [isVisible, setIsVisible] = useState(false);

    const handleScroll = (e) => {
        if (
            ref?.current.getBoundingClientRect().top < 0 
            && Math.abs(ref?.current.getBoundingClientRect().top) < ref?.current.offsetHeight
        ) {
            // initial += 1;
            setIsVisible(true);
        }
        else  {
            setIsVisible(false);
        }
    }   
    
    useEffect(() => {
        window.addEventListener('scroll', handleScroll);
        return () => window.removeEventListener('scroll', handleScroll);
    }, []);

    return isVisible;
}
