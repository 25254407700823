const labels = {
    general: {
        donate: 'Пожертвувати'
    },
    header: {
        menu: {
            war: 'Війна',
            disaster: 'Гуманітарна катастрофа',
            about: 'Про нас',
            activities: 'Наша діяльність',
            contacts: 'Контакти'            
        }
    },
    homepage: {
        hero: {
            title: 'Захищаємо Україну разом',
            subtitle: 'Ми докладаємо всіх зусиль, щоб допомогти вирішити проблему гуманітарної катастрофи'
        },
        war: {
            tag: 'ВІЙНА',
            text: 'Після незаконної анексії Криму від України та вторгнення немаркованої російської військової техніки на Донбас у 2014 році, через вісім років, <span>24.02.2022</span> Російська Федерація вторглася до кордонів України та розпочала повномасштабну війну проти громадян України.'
        },
        slider: {
            mariupol: {
                title: 'Маріуполь',
                region: 'Донецька область'
            },
            bucha: {
                title: 'Буча',
                region: 'Київська область'
            },
            irpin: {
                title: 'Ірпінь',
                region: 'Київська область'
            },
            kyiv: {
                title: 'Київ',
                region: 'Київська область'
            },
            kharkiv: {
                title: 'Харків',
                region: 'Харківська область'
            },
            odesa: {
                title: 'Одеса',
                region: 'Одеська область'
            },
            chernihiv: {
                title: 'Чернигів',
                region: 'Чернигівська область'
            },
            mykolaiv: {
                title: 'Миколаїв',
                region: 'Миколаївська область'
            },
        },
        result: {
            title: 'Внаслідок:',
            subtitle: 'З 24 лютого були зруйновані сотні українських міст і зламано мільйони життів.',
            cards: {
                exodus: {
                    title: 'Масові виїзди',
                    text: 'Люди були змушені залишати свої домівки та шукати притулку в інших країнах ЄС: Польщі, Литві, Німеччині та інших.'
                },
                damage: {
                    title: 'Величезний збиток',
                    text: 'Внаслідок масових расистських вибухів велика кількість будинків була зруйнована або пошкоджена. Відновлення може тривати кілька років.'
                },
                deaths: {
                    title: 'Тисячі смертей',
                    text: "Війна жорстоко і цинічно забрала життя багатьох українців, захисників, рятувальників, простих жінок, дітей, пенсіонерів."
                }
            }
        },
        disaster: {
            tag: 'ГУМАНІТАРНА КАТАСТРОФА',
            text: 'Гостра потреба в медичних та гігієнічних приладдях, продуктах харчування, одязі, захисних засобах тощо.',
            needed: '– необхідно',
            donate: {
                text: "Незважаючи на загальні зусилля, усі ці потреби залишаються <span>не повністю покритими.</span> Не залишайтеся осторонь, допоможіть нашим людям."
            }
        },
        organisation: {
            tag: 'ПРО НАС',
            title: 'Ми - громадська організація Bless Ukraine',
            text: 'Ми докладаємо всіх зусиль, аби допомогти вирішити проблему гуманітарної катастрофи в центрі Європи. Ми співпрацюємо із організацією <a href="https://www.junost-bayern.de/junostbayern/" target="_blank">JunOst Bayern</a>, що заснована у Мюнхені та працює вже понад 13 років.',
        },
        principles: {
            title: 'Наші основні принципи: ',
            items: {
                fast: 'Швидко реагувати на виклики гуманітарних потреб',
                avoiding: 'Уникнення непотрібних бюрократичних процедур',
                supporting: 'Підтримка безпосередньо місцевих одержувачів',
                costs: 'Без урахування витрат на модний маркетинг',
                prioritization: "Розподіл товарів відповідно до потреб регіонів (пріоритетність)"
            }
        },
        activities: {
            tag: 'НАША ДІЯЛЬНІСТЬ',
            cards: {
                essentials: {
                    title: 'Основні речі першої необхідності',
                    text: ['Придбання та доставка їжі', 'Гігієнічні засоби', 'Одяг', 'Ковдри, матраци тощо.']
                },
                info: {
                    title: 'Інформаційна допомога',
                    text: ['Консультування біженців щодо їхніх потреб та прав у Європі', 'Пошук зниклих безвісти людей']
                },
                medical: {
                    title: 'Медичні закупівлі',
                    text: ['Доставка аптечок, бинтів тощо в зонах бойових дій', 'Закупівля транспортних засобів першої допомоги']
                },
                protective: {
                    title: 'Захисні засоби',
                    text: ['Забезпечення захисними жилетами та касками для волонтерів фельдшерів на передовій та інших громадян']
                },
                pet: {
                    title: 'Догляд за домашніми тваринами',
                    text: ['Доставка кормів для домашніх тварин та необхідних ветеринарних препаратів', 'Дотримання законів про захист тварин', 'Пошук нового місця проживання для покинутих домашніх тварин']
                },
                transport: {
                    title: 'Транспортні послуги',
                    text: ['Допомога у транспортуванні хворих та поранених для збереження середовища']
                },
                political: {
                    title: 'Політичні заручини',
                    text: ['Створення та просування петицій щодо прав людини']
                },
                reconstructions: {
                    title: 'Реконструкційні роботи',
                    text: ['Допомога в прибиранні будівельного завалу', 'Допомога у порятунку людей з-під завалів']
                }
            }
        },
        what_can: {
            tag: "ПОЖЕРТВУВАННЯ",
            title: "Що ти можеш зробити",
            list: {
                first: "Підтримайте Україну, зробивши фінансовий внесок до нашого фонду. Кожна пожертва важлива.",
                second: "Читайте надійні ресурси."
            }
        },
        news: {
            war: {
                title: "Війна в Україні",
                text: "Інформація перевірена Міністерством закордонних справ України."
            },
            with: {
                title: "З Україною",
                text: "Волонтерська інформаційна організація."
            },
            president: {
                title: "Президент України",
                text: "Офіс Президента України."
            }
        },
        stand: {
            title: "Підтримайте Україну",
            text: "Дякую тобі, світу"
        },
        contact: {
            description: "Зв'яжіться із нами - ми тут, щоб допомогти вам",
            email_us: "Напишіть нам"
        }
    },
    // privacy: {
    //     title: 'Політика конфіденційності',
    //     content: {
    //         subtitle: "Інформація, яку ми збираємо",
    //         text: 'Тип особистої інформації, яку ми збираємо, залежить від того, як ви конкретно з нами взаємодієте та які Служби ви використовуєте. Як правило, ми збираємо такі категорії особистої інформації:',
    //         personal_list: {
    //             1: 'Контактна інформація, така як ім’я, псевдонім, адреса, номер телефону, ідентифікатор користувача соціальних мереж, адреса електронної пошти та подібні контактні дані.',
    //             2: 'Інформація про організацію та контакти, як-от вашого роботодавця або організації, членом яких ви є, інформацію про ваших колег або співробітників вашої організації, ваш статус в організації та подібні дані.',
    //             3: 'Інформація про обліковий запис, така як інформація, пов’язана з безпекою (включаючи імена користувачів та паролі, методи автентифікації та ролі), інформація, пов’язана з послугами (включаючи історію покупок та профілі облікового запису), інформація, пов’язана з рахунками (включаючи інформацію про оплату, доставку та платіжну інформацію) , і подібні дані.',
    //             4: 'Вміст користувача, такий як вміст повідомлень, пропозицій, запитань, коментарів, відгуків та іншої інформації, яку ви надсилаєте нам, яку ви надаєте нам, коли звертаєтесь до нас, або яку ви публікуєте в наших Сервісах (включаючи інформацію в сповіщеннях, папках, нотатки та розділи вмісту) та подібні дані.',
    //             5: 'Інформація про пристрій і браузер, така як інформація про мережу та підключення (включаючи адреси постачальника послуг Інтернету (ISP) та IP-адреси), ідентифікатори пристрою та браузера та інформація (включаючи тип пристрою, програми чи браузера, версію, тип плагіна і версія, операційна система, агент користувача, налаштування мови та часового поясу та інша технічна інформація), рекламні ідентифікатори, ідентифікатори та інформація cookie та подібні дані.'
    //         }
    //     }
    // },
    // imprint: {
    //     title: 'Відбиток',
    //     content: {
    //         subtitle: 'Інформація згідно з &sect; 5 TMG',
    //         text: 'Асоціація Bless Ukraine<br />c/o MJP Partners AG<br />Bahnhofstrasse 20<br />6300 Zug',
    //         representative: {
    //             title: 'ПредставленийПредставництво:',
    //             text: ['Karyna Gornishevska', 'Daria Tiurina']
    //         },
    //     },
    //     contact: {
    //         title: 'Контакти',
    //         phone: 'Телефон',
    //         mail: 'E-Mail'
    //     },
    //     accept: {
    //         title: 'Вирішення споживчих спорів/універсальна арбітражна рада',
    //         text: 'Ми не бажаємо або не зобов’язані брати участь у процедурах вирішення спорів у арбітражній раді споживачів.'
    //     }
    // },

    form: {
        sidebar: {
            donation: "Пожертвування карткою",
            description: "* Зараз ми працюємо за зручною системою прямих пожертв.",
            btn: "Скоро в наявності"
        },
        main: {
            fields: {
                destination: 'Одержувач',
                iban: "IBAN",
                bic: "BIC",
                purpose: 'Призначення платежу',
                donation: 'Пожертва',
            },
            copy: "Скопійовано !",
            required: "* Якщо потрібно, ми можемо видати квитанцію про пожертвування, щоб ви могли вирахувати її зі своїх податків. Для цього надішліть нам запит електронною поштою.",
            btn: "Попросити квитанцію",
        },
        popup: {
            title: "Запит на отримання",
            person: {
                title: "Фізична особа",
                name: "ІМ'Я ПРІЗВИЩЕ*",
                address: "АДРЕСА*",
                email: "EMAIL*",
                amount: "СУМА ПОЖЕРТВУВАННЯ*",
                date: "ДАТА ПОЖЕРТВУВАННЯ*"
            },
            company: {
                title: "Компанія",
                name: "НАЗВА КОМПАНІЇ*",
                address: "АДРЕСА КОМПАНІЇ*",
                email: "EMAIL*",
                amount: "СУМА ПОЖЕРТВУВАННЯ*",
                date: "ДАТА ПОЖЕРТВУВАННЯ*"
            },
            required: "* ОБОВ'ЯЗКОВІ ПОЛЯ",
            btn: "Відправити запит",
            success: {
                title: "Успішно",
                text: "Ваша форма успішно надіслана!"
            }
        }
    },
    cookies: {
        text: 'Цей веб-сайт використовує файли cookie, щоб забезпечити вам найкращий досвід роботи на нашому веб-сайті.',
        link: "Політика щодо файлів cookie"
    },
    footer: {
        privacy: "Політика конфіденційності",
        insta: "Інстаграм",
        facebook: "Фейсбук",
        photo: "Фотограф ",
        design: "Дизайн ",
        imprint: "Відбиток"
    }
};

export default labels;
