import i18n from "i18next";

import Tag from "../../components/Tag";
import foodIcon from "../../assets/icons/food.svg";
import antisepticIcon from "../../assets/icons/antiseptic.svg";
import sleepIcon from "../../assets/icons/sleep.svg";
import searchIcon from "../../assets/icons/search.svg";
import aidIcon from "../../assets/icons/aid.svg";
import helmetIcon from "../../assets/icons/helmet.svg";
import armorIcon from "../../assets/icons/body-armor.svg";
import listsIcon from "../../assets/icons/lists.svg";
import medicamentsicon from "../../assets/icons/medicament.svg";
import feedIcon from "../../assets/icons/feed.svg";
import petsaveIcon from "../../assets/icons/petsave.svg";
import boothIcon from "../../assets/icons/booth.svg";
import transportIcon from "../../assets/icons/transport.svg";
import securityIcon from "../../assets/icons/security.svg";
import excavatorIcon from "../../assets/icons/excavator.svg";
import handsIcon from "../../assets/icons/hands.svg";
import jaketIcon from "../../assets/icons/jaket.svg";

const Activities = () => {
    const cards = createCards();

    return (
        <div className="app-activities" id="activities">
            <div className="container">
                <Tag text={i18n.t('homepage.activities.tag')} color={'black'}/>

                <div className="app-activities-cards">
                    {
                        cards.map(item => (
                            <div key={item.title} className={`app-activities-cards-item ${i18n.language}`}>
                                <div className="app-activities-cards-item-text">
                                    <h4>{item.title}</h4>
                                    {item.text.map(elem => <p key={elem}>{elem}</p> )}
                                </div>

                                <div className="app-activities-cards-item-icons">
                                    {item.icons.map(elem => <img key={elem} src={elem} alt="" /> )}
                                </div>
                            </div>
                        ))
                    }
                </div>
            </div>
        </div>
    );
}

export default Activities;

function createCards() {
    return [
        {
            title: i18n.t('homepage.activities.cards.essentials.title'),
            text: i18n.t('homepage.activities.cards.essentials.text', { returnObjects: true }),
            icons: [
                foodIcon,
                antisepticIcon,
                jaketIcon,
                sleepIcon
            ]
        },
        {
            title: i18n.t('homepage.activities.cards.info.title'),
            text: i18n.t('homepage.activities.cards.info.text', { returnObjects: true }),
            icons: [listsIcon, searchIcon]
        },
        {
            title: i18n.t('homepage.activities.cards.medical.title'),
            text: i18n.t('homepage.activities.cards.medical.text', { returnObjects: true }),
            icons: [medicamentsicon, aidIcon]
        },
        {   
            title: i18n.t('homepage.activities.cards.protective.title'),
            text: i18n.t('homepage.activities.cards.protective.text', { returnObjects: true }),
            icons: [armorIcon, helmetIcon]
        },
        {
            title: i18n.t('homepage.activities.cards.pet.title'),
            text: i18n.t('homepage.activities.cards.pet.text', { returnObjects: true }),
            icons: [feedIcon, petsaveIcon, boothIcon]
        },
        {
            title: i18n.t('homepage.activities.cards.transport.title'),
            text: i18n.t('homepage.activities.cards.transport.text', { returnObjects: true }),
            icons: [transportIcon]
        },
        {
            title: i18n.t('homepage.activities.cards.political.title'),
            text: i18n.t('homepage.activities.cards.political.text', { returnObjects: true }),
            icons: [securityIcon]
        },
        {
            title: i18n.t('homepage.activities.cards.reconstructions.title'),
            text: i18n.t('homepage.activities.cards.reconstructions.text', { returnObjects: true }),
            icons: [excavatorIcon, handsIcon]
        }
    ];
}
