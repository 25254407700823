import React from 'react';
import ReactDOM from 'react-dom/client';
import { BrowserRouter as Router } from 'react-router-dom';
import { I18nextProvider } from 'react-i18next';
import i18n from './i18n';

import 'bootstrap/dist/css/bootstrap.min.css';
// NOTE: Required by slider?
import $ from 'jquery';
// NOTE: Popover librarys
import Popper from 'popper.js';
import 'bootstrap/dist/js/bootstrap.bundle.min';

import './index.scss';
import { BlessUkraine } from './BlessUkraine';
import reportWebVitals from './reportWebVitals';

const root = ReactDOM.createRoot(document.getElementById('root'));

root.render(
  <I18nextProvider {...{ i18n }}>
      <Router>
        <BlessUkraine />
      </Router>
  </I18nextProvider>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
