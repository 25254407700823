import { useState, useEffect } from 'react';

export const useIntersection = (element, rootMargin) => {
    const [isVisible, setState] = useState(false);

    useEffect(() => {
        if (element?.current) {
            const observer = new IntersectionObserver(
                ([entry]) => {
                    setState(entry.isIntersecting);
                }, { root: null, rootMargin }
            );
    
            element.current && observer.observe(element.current);
    
            // if (element.current) {
            //     return () => observer.unobserve(element?.current);
            // }
        }
    }, []);
    
    return isVisible;
};
