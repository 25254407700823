import Tag from "../../components/Tag";
import Form from "../Form";
import News from "../News";
import i18n from "i18next";

const WhatCan = () => (
    <section className="app-what-can" id="what-can">
        <div className="container">
            <Tag text={i18n.t("homepage.what_can.tag")} color="black"/>
            <h2>{i18n.t("homepage.what_can.title")}</h2>
            
            <div className="col-lg-7">
                <p><span>1</span>{i18n.t("homepage.what_can.list.first")}</p>
            </div>                
            <Form/>

            <p><span>2</span> {i18n.t("homepage.what_can.list.second")}</p>
            <News/>
        </div>
    </section>
);

export default WhatCan;
