import { Link } from "react-router-dom";
import arrowIcon from "../../assets/icons/arrow-link.svg";
import i18n from "i18next";
import { Tracking } from '../../helpers/tracking';

const News = () => {
    const links = createLinks();

    const onLinkClick = (url) => () => {
        Tracking.visitTrustfulResource(url);
    };

    return (
        <section className="app-news">
            {
                links.map((item, index) => (
                    <div key={index} className="app-news-item">
                        <a 
                            href={item.link} 
                            onClick={onLinkClick(item.link)}
                            target="_blank" 
                            rel="noopener noreferrer"
                        >
                            <div className="app-news-item-text">
                                <h4>{item.title}</h4>
                                <p>{item.text}</p>
                            </div>
                            <img src={arrowIcon} alt="" />
                        </a>
                    </div>
                ))
            }
        </section>
    );
}

export default News;

function createLinks() {
    return [
        {
            title: i18n.t('homepage.news.war.title'),
            text: i18n.t('homepage.news.war.text'),
            link: 'https://war.ukraine.ua/'
        },
        {
            title: i18n.t('homepage.news.with.title'),
            text: i18n.t('homepage.news.with.text'),
            link: 'https://instagram.com/withukraine?igshid=YmMyMTA2M2Y='
        },
        {
            title: i18n.t('homepage.news.president.title'),
            text: i18n.t('homepage.news.president.text'),
            link: 'https://www.president.gov.ua/en'
        },
    ];
}
