import React from 'react';
import {Route, Routes } from 'react-router-dom';
import Home from './pages/Home';
import Privacy from './pages/Privacy';
import Imprint from './pages/Imprint'
import { CookieAlert } from './components/CookieAlert';
import { classnames } from './helpers/classnames';
import { HeaderContextProvider } from './hooks/use-header-theme';
import { useDocumentLoaded } from './hooks/use-document-loaded';
import { useCookieConsent } from './hooks/use-cookie-consent';

export const BlessUkraine = () => {
  const { cookiesAccepted, acceptCookies } = useCookieConsent();
  const documentLoaded = useDocumentLoaded();

  const contentClasses = classnames('app-wrap', {
    'hidden': !documentLoaded,
  });

  return (
    <div className="app">
      <HeaderContextProvider>
        <div className={contentClasses}>
          <Routes>
            <Route path='/' element={<Home />} />
            <Route path='/privacy' element={<Privacy />} />
            <Route path='/imprint' element={<Imprint />} />
          </Routes>
          {!cookiesAccepted && <CookieAlert acceptCookies={acceptCookies} />}
        </div>
      </HeaderContextProvider>
    </div>
  );
}
