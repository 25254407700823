import { useEffect, useState } from 'react';

export const useScreenWidth = () => {
    const [dimension, setDimension] = useState(getDimension());
    
    const resizeHandle = () => setDimension(getDimension());

    useEffect(() => {
        window.addEventListener('resize', resizeHandle);
        // return () => window.removeEventListener('resize', resizeHandle, false);
    }, []);

    return dimension;
};

function getDimension() {
    if (window.innerWidth > 1200) {
        return 'xl';    
    }
    else if (window.innerWidth < 1200 && window.innerWidth > 992) {
        return 'lg';    
    }
    else if (window.innerWidth < 992 && window.innerWidth > 768) {
        return 'md';    
    }
    else if (window.innerWidth <= 768) {
        return 'sm';    
    }
};
