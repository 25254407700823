import { useState, useRef, useEffect } from "react";
import { useLocation } from "react-router-dom";
import { Navbar, Nav } from "react-bootstrap";
import { HashLink as Link } from 'react-router-hash-link';
import i18n from "i18next";
import pt from 'prop-types';

import LogoLight from "../../assets/img/rest/svg/logo-light.svg";
import LogoDark from "../../assets/img/rest/svg/logo-dark.svg";
import instaIcon from "../../assets/icons/insta.svg";
import Button from "../../components/Button";
import LanguageSwitcher from "../../components/LanguageSwitcher";
import { useHeaderContext } from '../../hooks/use-header-theme';
import { Tracking } from '../../helpers/tracking';

const Header = ({ theme }) => {
    const location = useLocation().pathname;
    const menu = headerMenu();

    const [headerMobileOpened, setHeaderMobileOpened] = useState(false);
    const navbarRef = useRef(null);
    const togglerRef = useRef(null);
    const headerRef = useRef(null);
    const { headerTheme: contextTheme } = useHeaderContext();

    useEffect(() => {
        if (navbarRef.current && togglerRef && window.innerWidth < 1200 && !headerMobileOpened) {
            navbarRef.current.classList.remove('show');
            togglerRef.current.classList.add('collapsed');
        }
    }, [headerMobileOpened]);

    useEffect(() => {
        document.addEventListener('click', (e) => {
            if (headerRef?.current && !headerRef?.current.contains(e.target)) {
                // togglerRef.current?.focus();
                setHeaderMobileOpened(false);
            }
        });
    }, []);

    const NavLink = ({ children, url, className }) => {
        return (
            location === '/' ?
                <Nav.Link className={className ?? ''} href={url}>{children}</Nav.Link>
            :
                <Link className={`nav-link ${className ?? ''}`} to={`/${url}`}>{children}</Link>
        )
    }

    const colorTheme = theme || contextTheme;

    const onInstaClick = () => Tracking.visitInstagram();
    const onDonateClick = () => {
        setHeaderMobileOpened(false);
        Tracking.scrollToDonate();
    };

    return (
        <div className={`app-header ${colorTheme}`} ref={headerRef}>
            <Navbar className="navbar" expand="xl">
                <Navbar.Brand className=" mobile" href={`${location == '/' ? '#hero' : '/'}`}  onClick={() => setHeaderMobileOpened(false)}>
                    <img src={LogoLight} className="navbar-brand-logo-light" alt="" />
                    <img src={LogoDark} className="navbar-brand-logo-dark" alt="" />
                </Navbar.Brand>

                <div className="navbar-right">
                    {/* <Nav.Link href="/" target="_blank" className="icon">
                        <img src={faceookIcon} alt="" />
                    </Nav.Link> */}

                    <a 
                        href="https://www.instagram.com/bless.ukraine/" 
                        onClick={onInstaClick} 
                        className="icon" 
                        target="_blank"
                        rel="noreferrer noopener"
                    >
                        <img src={instaIcon} alt="" />
                    </a>
                    {/* <Nav.Link href="/" target="_blank" >
                    </Nav.Link> */}

                    <LanguageSwitcher className={colorTheme}/>

                    <NavLink url={'#what-can'} className="btn">
                        <Button type={'submit'} onClick={onDonateClick}>{i18n.t("general.donate")}</Button>

                    </NavLink>
                </div>

                <Navbar.Toggle aria-controls="navbarScroll" ref={togglerRef} onClick={() => setHeaderMobileOpened(true)}>
                    <span className="navbar-toggler-icon first"></span>
                    <span className="navbar-toggler-icon second"></span>
                    <span className="navbar-toggler-icon third"></span>
                </Navbar.Toggle >


                <Navbar.Collapse id="navbarScroll" ref={navbarRef}>
                    <Navbar.Brand className="desktop" href={`${location == '/' ? '#hero' : '/'}`}>
                        <img src={LogoLight} className="navbar-brand-logo-light" alt="" />
                        <img src={LogoDark} className="navbar-brand-logo-dark" alt="" />
                    </Navbar.Brand>

                    <Nav navbarScroll className={i18n.language == 'ua' ? 'relative' : ''}>
                        {menu.map(item => (
                            <li key={item.name} className={`nav-item ${item.className ?? ''}`} onClick={() => setHeaderMobileOpened(false)}>
                                {/* <Nav.Link href={item.link}> */}
                                <NavLink url={item.link} key={item}>

                                    {
                                        item.className == 'btn' ?
                                        <Button type={'submit'} onClick={onDonateClick}>{i18n.t("general.donate")}</Button>
                                        :
                                        item.name
                                    }
                                </NavLink>
                            </li>
                        ))}
                    </Nav>
                </Navbar.Collapse>

            </Navbar>
        </div>
    )
}

Header.propTypes = {
    theme: pt.oneOf(['light', 'dark']),
};

export { Header };

function headerMenu() {
    return [
        {
            name: i18n.t('header.menu.war'),
            link: '#war'
        },
        {
            name: i18n.t('header.menu.disaster'),
            link: '#disaster'
        },
        {
            name: i18n.t('header.menu.about'),
            link: '#about'
        },
        {
            name: i18n.t('header.menu.activities'),
            link: '#activities'
        },
        {
            name: i18n.t('header.menu.contacts'),
            link: '#contact'
        },
        {
            name: i18n.t('general.donate'),
            link: '#what-can',
            className: 'btn'
        },
    ];
}
