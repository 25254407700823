import { Link } from 'react-router-dom';
import i18n from 'i18next';
import pt from 'prop-types';
import cookieIcon from '../../assets/icons/cookie.svg';

const CookieAlert = ({ acceptCookies }) => (
    <div className="app-cookie">
        <div className="app-cookie-message">
            <img src={cookieIcon} alt="" />
            <p>{i18n.t('cookies.text')}</p>
            <Link to="/">{i18n.t('cookies.link')}</Link>
        </div>
        <div className="app-cookie-close" onClick={() => acceptCookies()}></div>
    </div>
);

CookieAlert.propTypes = {
    acceptCookies: pt.func.isRequired,
};

export { CookieAlert };
