import { Header } from "../../sections/Header";
import Footer from "../../sections/Footer";
import Contact from "../../sections/Contact";
import i18n from "i18next";
import { Interweave } from "interweave";

const Imprint = () => {
    return (
        <div className="app-privacy">
            <Header theme="light"/>
            
            <h1>{i18n.t('imprint.title')}</h1>
            
            <section className="app-privacy-content">
                <div className="container">
                    <Interweave containerTagName="h2" content={i18n.t('imprint.content.subtitle')} />
                    <Interweave containerTagName="p" content={i18n.t('imprint.content.text')} />

                    <h3>{i18n.t('imprint.content.representative.title')}</h3>
                    <ul>
                        {
                            Object.keys(i18n.t('imprint.content.representative.text', {returnObjects: true})).map(item => (
                                <li key={item}>{i18n.t('imprint.content.representative.text', {returnObjects: true})[item]}</li>
                            ))
                        }
                    </ul>

                    <div className="app-privacy-block">
                        <h3>{i18n.t('imprint.contact.title')}</h3>
                        <ul>
                            <li>
                                <span>{i18n.t('imprint.contact.phone')}: </span>
                                <a href="tel:+49 157 325 06 232" target="_blank">+49 157 325 06 232</a>
                            </li>
                            <li>
                                <span>{i18n.t('imprint.contact.phone')}: </span>
                                <a href="tel:+49 151 700 84 760" target="_blank">+49 151 700 84 760</a>
                            </li>
                            <li>
                                <span>{i18n.t('imprint.contact.mail')}: </span>
                                <a href="mailto:info@bless-ukraine.com" target="_blank">info@bless-ukraine.com</a>
                            </li>
                        </ul>
                    </div>

                    <div className="app-privacy-block">
                        <h3>{i18n.t('imprint.accept.title')}</h3>
                        <p>{i18n.t('imprint.accept.text')}</p>
                    </div>
                </div>
            </section>

            <Contact />
            <Footer />
        </div>
    )
}

export default Imprint;
