import React, { createContext, useContext, useState } from 'react';
import pt from 'prop-types';

const HeaderContext = createContext();

const HeaderTheme = {
    LIGHT: 'light',
    DARK: 'dark',
};

const HeaderContextProvider = ({ children }) => {
    const [theme, setTheme] = useState(HeaderTheme.DARK);
    const enableLightTheme = () => setTheme(HeaderTheme.LIGHT);
    const enableDarkTheme = () => setTheme(HeaderTheme.DARK);

    const contextValue = { 
        headerTheme: theme,
        enableLightTheme,
        enableDarkTheme,
    };

    return (
        <HeaderContext.Provider value={contextValue}>
            {children}
        </HeaderContext.Provider>
    );
};

const useHeaderContext = () => useContext(HeaderContext);

HeaderContextProvider.propTypes = {
    children: pt.node,
};

export { 
    HeaderContextProvider,
    useHeaderContext,
};
