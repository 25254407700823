import { useEffect, useState, useRef } from 'react';

import arrowIcon from "../../assets/icons/arrow.svg";

import { useIntersection } from '../../hooks/use-intersection';
import { useScreenWidth } from '../../hooks/use-screen-width';
import VisibilitySensor from 'react-visibility-sensor';

import { slider } from './slider';

let slideShowTimer = null;
let sliderInterval = null;

const Slick = () => {
    const [renderSlider, setRenderSlider] = useState(slider);
    const screenWidth = useScreenWidth();
    
    // Init when scroll
    const sliderRef = useRef(null);
    const sliderIsVisible = useIntersection(sliderRef, '-40%') 
    const [isInit, setIsInit] = useState(false);

    useEffect(() => {
        if (!isInit && sliderIsVisible) {
            setIsInit(true);
        }
    }, [sliderIsVisible]);


    // Mobile Options
    const [slidesToShow, setSlidesToShow] = useState(4);
    const sliderRefs = useRef([]);
    const [arrowDisabled, setArrowDisabled] = useState(false);

    useEffect(() => {
        if (screenWidth !== 'sm') {
            setRenderSlider(slider.concat(slider));
        }
        else {
            if (sliderInterval) {
                clearInterval(sliderInterval);
            }

            setTransform(0);
            setRenderSlider(slider);
        }
    }, [screenWidth]);

    // const [isMoving, setIsMoving] = useState(false);
    const [isHover, setIsHover] = useState(false);
    const [currentImg, setCurrentImg] = useState(0);
    let slideWidth = sliderRefs?.current[0]?.offsetWidth ?? 550;
    const [transform, setTransform] = useState(0);

    // const [scroll, setScroll] = useState(false);

    const moveAnimation = () => {

        sliderInterval = setInterval(() => {
            if (isHover === false) {
                setTransform((prev) => {
                    // if (prev <= -((slideWidth + 24) * slider.length) && isMoving) {
                    //     return 0;
                    // }
                    // else {
                        // if (isMoving) {
                        //     setIsMoving(false);
                        //     return isMoving;
                        // }
                        // Right
                        if (prev <= -((slideWidth + 24) * slider.length)) {
                            let different = (Math.abs(prev) - (sliderRefs.current[0].offsetWidth + 24) * slider.length);
                            return 0 - different;
                        }

                        // Left
                        else if (prev > 0) {
                            return -((slideWidth + 24) * slider.length) + prev;                                                                                    
                        }
                        return prev - .3;
                    // }
                });
            }
            else {
                clearInterval(sliderInterval);
            }
        }, 0);
    }

    const slideShowAnimation = () => {
        if (typeof isHover == 'number') {
            slideShowTimer = setInterval(() => {
                setCurrentImg(prev => (prev >= ( renderSlider[isHover].img.length - 1 )) ? 0 : prev+=1);
            }, 2500);
        } 
        else {
            setCurrentImg(0);
            clearInterval(slideShowTimer);
        }
    }

    useEffect(() => {
        if (sliderRefs?.current) {
            setCurrentImg(0);
            if (screenWidth !== 'sm' ) {
                if (isHover === false && isInit) {
                    moveAnimation();
                }
                else {
                    slideShowAnimation();
                }
            }
            else {
                if (typeof isHover == 'number') {
                    slideShowAnimation();
                }    
                // else {
                //     setCurrentImg(0);
                // }
            }
        }

        return () => {
            clearInterval(sliderInterval);
            clearTimeout(slideShowTimer);
        };
    }, [isHover, isInit]);

    // const Slide = ({ children, item, index }) => {
    //     return (      
    //         screenWidth == 'sm' ?      
    //             <VisibilitySensor
    //                 scrollThrottle={0}
    //                 delayedCall={true}
    //                 partitialVisibility={false}
    //                 active={index !== isHover}
    //                 onChange={(isVisible) => {
    //                     if (screenWidth == 'sm') {
    //                         if (isVisible == true && index !== isHover) {
    //                             setIsHover(index);
    //                         }
    //                     }
    //                 }}
    //             >
    //                 <div 
    //                     className={`app-slider-item ${isHover === index ? 'hovered' : ''} ${(screenWidth == 'sm' && index >= slidesToShow) ? 'hidden' : ''}`}
    //                     key={item.title + index} 
    //                     ref={el => sliderRefs.current[index] = el}
    //                     >
    //                     {children}
    //                 </div>
    //             </VisibilitySensor>
    //         :
    //             <div 
    //                 className={`app-slider-item ${isHover === index ? 'hovered' : ''}`}
    //                 key={item.title + index} 
    //                 ref={el => sliderRefs.current[index] = el}
    //                 onMouseEnter={() => screenWidth !== 'sm' ? setIsHover(index) : false}
    //                 onMouseLeave={() => screenWidth !== 'sm' ? setIsHover(false) : false}
    //                 >
    //                 {children}
    //             </div>
            
    //     )
    // }

    return (
        <div className="app-slider">
            <div 
                className={`app-slider-wrap ${renderSlider.length > slidesToShow ? 'blur' : ''}`} 
                style={{ 
                    transform: `translateX(${transform}px)`,
                    transition: (isHover === true) ? 'transform 0.5s cubic-bezier(.25,.72,.51,.96)' : 'none'
                }}
                ref={sliderRef}>
                {renderSlider.map((item, index) => {

                    return (
                        <VisibilitySensor
                            // scrollThrottle={0}
                            scrollCheck={true}
                            delayedCall={true}
                            partitialVisibility={false}
                            key={item.title + index} 
                            active={screenWidth == 'sm' }
                            onChange={(isVisible) => {
                                if (screenWidth == 'sm') {
                                    if (isVisible == true && index !== isHover) {
                                        setIsHover(index);
                                    }
                                }
                            }}
                        >
                            <div 
                                onMouseEnter={() => screenWidth !== 'sm' ? setIsHover(index) : false}
                                onMouseLeave={() => screenWidth !== 'sm' ? setIsHover(false) : false}
                                className={`app-slider-item ${isHover === index ? 'hovered' : ''} ${(screenWidth == 'sm' && index >= slidesToShow) ? 'hidden' : ''}`}
                                key={item.title + index} 
                                ref={el => sliderRefs.current[index] = el}
                                >
                            <>
                                <div className="app-slider-item-img-wrap">
                                    {
                                        item.img.map((photo, photoIndex) => (
                                            <img 
                                                key={photo.by + photoIndex} 
                                                src={photo.photo} 
                                                className={`app-slider-item-img${((isHover === index && currentImg === photoIndex) || (photoIndex === 0 && (isHover !== index || typeof isHover == 'boolean') )) ? ' current' : ''}`} 
                                                alt="" 
                                            />
                                        ))
                                    }
                                </div>
        
                                    <div className={`app-slider-item-text ${(screenWidth == 'sm' && slidesToShow < renderSlider.length && index == slidesToShow - 1) ? 'hide' : ''}`}>
                                        <h4>{item.title}</h4>
                                        <h5>{item.region}</h5>
                                    </div>
                                    
                                    {
                                        item.img.map((tag, tagIndex) => (
                                            <span key={tagIndex + tag.by} className={`photo-by${((isHover === index && currentImg === tagIndex) || (tagIndex == 0 && isHover !== index)) ? ' current' : ''}`}>{tag.by}</span>
                                        ))
                                    }
                            </>
                            </div>
                        </VisibilitySensor>
                    )     
                } 
                )}
            </div>

            {
                (screenWidth == 'sm' && slidesToShow < renderSlider.length) ?
                    <span onClick={() => {
                        // if (slidesToShow + 1 < renderSlider.length && sliderRefs?.current.length) {
                        //     window.scrollTo(0, window.pageYOffset - (sliderRefs.current[0].offsetHeight / 2))
                        // }
                        setSlidesToShow(slider.length);
                    }} className="app-slider-more">Show more photos</span>
                : null
            }
                    
            <div className="app-slider-arrows">
                <div className="container">                
                    <div className="app-slider-arrows-item left" onClick={() => {
                            if (!arrowDisabled) {
                                let different = (((sliderRefs.current[0].offsetWidth * slider.length) - Math.abs(transform)) / slider.length);
                                let slideWidth = sliderRefs.current[0].offsetWidth;
                                
                                // if (transform + slideWidth >= 0) {
                                //     setIsMoving(-((slideWidth + 24) * slider.length) + transform);                                    
                                // }
                                // else {
                                    setArrowDisabled(true);
                                    setIsHover(true)
                                    setTransform(prev => prev + slideWidth);
                                    setTimeout(() => {
                                        setIsHover(false);
                                        setArrowDisabled(false);
                                    }, 500);
                                // }
                            }

                    }}>
                        <img src={arrowIcon} alt="" />
                    </div>

                    <div className="app-slider-arrows-item right" onClick={() => {
                        if (!arrowDisabled) {
                            let slideWidth = sliderRefs.current[0].offsetWidth;

                            // if (transform - slideWidth <= -((slideWidth + 24) * (slider.length + 1))) {
                            //     let different = (Math.abs(transform) - (sliderRefs.current[0].offsetWidth + 24) * slider.length);
                            //     setIsMoving(0 - different);
                            // }
                            // else {
                                setArrowDisabled(true);
                                setIsHover(true);
                                setTransform((prev) => prev - sliderRefs.current[0].offsetWidth)
                                setTimeout(() => {
                                    setIsHover(false);
                                    setArrowDisabled(false);
                                }, 500);
                            // }

                        }
                    }}>
                        <img src={arrowIcon} alt="" />
                    </div>
                </div>
            </div>
        </div>
    )
}
                
export default Slick;
                