import { useEffect, useState } from 'react';
import { useLocation } from 'react-router-dom';

export const useDocumentLoaded = () => {
    const [loaded, setLoaded] = useState(false);
    const { pathname } = useLocation();

    const loadHandle = () => {
        setLoaded(true);
    };

    useEffect(() => {
        window.scrollTo(0, 0);

        if (document.readyState === 'complete') {
            loadHandle();
        } else {
            window.addEventListener('load', loadHandle);

            return () => window.removeEventListener('load', loadHandle, false);
        }
    }, [pathname]);

    return loaded;
};
