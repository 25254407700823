import speedIcon from "../../assets/icons/speed.svg";
import listsIcon from "../../assets/icons/priciples-lists.svg";
import mapIcon from "../../assets/icons/map.svg";
import coinsIcon from "../../assets/icons/coins.svg";
import boxIcon from "../../assets/icons/box.svg";
import i18n from "i18next";

const Principles = () => {
    const principles = createPrinciples();

    return (
        <section className="app-principles">
            <div className="container">
                <h3>{i18n.t('homepage.principles.title')}</h3>

                <div className="app-principles-wrap">
                    {
                        principles.map(item => (
                            <div className="app-principles-item" key={item.text}>
                                <img src={item.icon} alt="" />
                                <p>{item.text}</p>
                            </div>
                        ))
                    }
                </div>
            </div>
        </section>
    )
}

export default Principles;

function createPrinciples() {
    return [
        {
            icon: speedIcon,
            text: i18n.t('homepage.principles.items.fast')
        },
        {
            icon: listsIcon,
            text: i18n.t('homepage.principles.items.avoiding')
        },
        {
            icon: mapIcon,
            text: i18n.t('homepage.principles.items.supporting')
        },
        {
            icon: coinsIcon,
            text: i18n.t('homepage.principles.items.costs')
        },
        {
            icon: boxIcon,
            text: i18n.t('homepage.principles.items.prioritization')
        }
    ];
}
