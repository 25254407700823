import { useEffect, useState } from 'react';
import * as Cookie from '../helpers/cookie';

export const useCookieConsent = () => {
    const dateCookieAccepted = Cookie.getItem('bless-ukraine');
    const [cookiesAccepted, setAcceptance] = useState(!!dateCookieAccepted);

    useEffect(() => {
        if (cookiesAccepted) {
            Cookie.setItem('bless-ukraine', new Date());
        }
    }, [cookiesAccepted]);

    return {
        acceptCookies: () => setAcceptance(true),
        cookiesAccepted,
    };
};
