import { Link } from 'react-router-dom';

const Button = ({target, children, link, className, type, onClick, disabled = false}) => {
    
    if (type && type == 'submit') {
        return (
            <button
                className={`app-button ${className ?? ''}`}
                onClick={onClick}
                disabled={disabled}
            >
                {children ?? 'Submit'}
            </button>
        )
    }
    else if (type && type == 'href') {
        return (
            <a
                href={link}
                className={`app-button ${className ?? ''}`}
                onClick={onClick}
                disabled={disabled}
                target={target ?? "_blank"}
            >
                {children ?? 'Submit'}
            </a>
        )
    }

    return (
        <Link 
            to={link ? link : ''}
            className={`app-button ${className ?? ''}`}
        >
            {children ?? 'Donate'}
        </Link>
    )
}

export default Button;