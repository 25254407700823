import { Link } from "react-router-dom";
import i18n from "i18next";

const Footer = () => {
    const footerMenu = createFooterMenu();

    return (
        <section className="app-footer">
            <div className="container">
                <div className="row">
                    <div className="col-lg-3">
                        <span>© Bless Ukraine 2022</span>
                    </div>

                    <div className="col-lg-9">
                        <ul className="app-footer-menu">
                            {footerMenu.map((item, index) => (
                                <li key={index} className={item.className}>{item.content}</li>
                            ))}
                        </ul>
                    </div>
                </div>
            </div>
        </section>
    );
}

export default Footer;

function createFooterMenu() {
    return [
        {   
            className: 'app-footer-link imprint',
            content: <Link to="/imprint">{i18n.t("footer.imprint")}</Link>,
        },
        {
            className: "app-footer-link photo",
            content: <span>{i18n.t('footer.photo')}<a target="_blank" href="https://www.instagram.com/libkos/">@libkos</a></span>,
        },    
        // {
        //     className: "app-footer-link facebook",
        //     content: <a href="/">{i18n.t("footer.facebook")}</a>,
        // },
        {
            className: 'app-footer-link designed',
            content: <span>{i18n.t('footer.design')}<a target="_blank" href="https://trinetix.com/">Trinetix</a></span>,
        },
        {
            className: 'app-footer-link privacy',
            content: <Link to="/privacy">{i18n.t("footer.privacy")}</Link>,
        },
        // {
            //     className: 'app-footer-link cookies',
            //     content: <Link to="/">{i18n.t("cookies.link")}</Link>,
            // },
        {
            className: "app-footer-link insta",
            content: <a target={"_blank"} href="https://www.instagram.com/bless.ukraine/">{i18n.t("footer.insta")}</a>,
        },
        {
            className: 'app-footer-link developed',
            content: <span>{i18n.t("footer.developed")}<a target="_blank" href="https://perfectorium.com/">Perfectorium</a></span>,
        },
        
        // <Link to="/">{i18n.t("cookies.link")}</Link>,
    ];
}
