import Logo from "../../assets/img/rest/svg/contact-logo.svg";
import mailIcon from "../../assets/icons/mail.svg";

import Button from "../../components/Button";
import i18n from "i18next";
import { Tracking } from '../../helpers/tracking';

const INFO_EMAIL = 'info@bless-ukraine.com';

const Contact = () => {
    const onEmailUs = (email) => () => {
        Tracking.emailUs(email);
    };

    return (
        <section className="app-contact" id="contact">
            <div className="container">
                <img src={Logo} className="app-contact-img" alt="" />
                <p>{i18n.t("homepage.contact.description")}</p>

                <div className="app-contact-cta">
                    <Button 
                        type="href" 
                        link={`mailto:${INFO_EMAIL}`}
                        onClick={onEmailUs(INFO_EMAIL)}
                    >
                        <img src={mailIcon} alt="" />
                        {INFO_EMAIL}
                    </Button>
                </div>
            </div>
        </section>
    );
}

export default Contact;
