import { ParallaxProvider, Parallax } from 'react-scroll-parallax';
import { useScreenWidth } from '../../hooks/use-screen-width';
import { useEffect, useState } from 'react';

const Photo = ({ src, by, children, parrallax, alt, ...imgProps }) => {
    const screenWidth = useScreenWidth();
    const [parallaxSpeed, setParallaxSpeed] = useState(screenWidth == 'sm' ? 0 : -30);

    useEffect(() => {
        if (screenWidth == 'sm') {
            setParallaxSpeed(0);
        }
        else {
            setParallaxSpeed(-30);
        }
    }, [screenWidth])

    const PhotoWrap = ({children}) => {
        return (
            (parrallax && screenWidth !== 'sm') ?
                <ParallaxProvider>
                    <Parallax speed={parallaxSpeed}>
                        {children}
                    </Parallax>
                </ParallaxProvider>
            :
            <>
                {children}
            </> 

        )
    };

    return (
        <div className={`app-photo`}>
            <PhotoWrap>
                <img src={src} alt={alt} {...imgProps} />
            </PhotoWrap>

                <div className="app-photo-content">
                    {children ?? null }
                </div>
                
                {by ? <span className="photo-by">{by}</span>: null}
        </div>
    );
}

export default Photo;
