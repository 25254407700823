import { useRef, useState } from 'react';
import { useScreenWidth } from '../../hooks/use-screen-width';
import Slider from "react-slick";

import disOne from '../../assets/img/rest/webp/disaster_1.webp';
import disTwo from '../../assets/img/rest/webp/disaster_2.webp';
import disThree from '../../assets/img/rest/webp/disaster_3.webp';
import disFour from '../../assets/img/rest/webp/disaster_4.webp';

import Arrow from "../../assets/icons/arrow.svg";

import Tag from "../../components/Tag";
import Button from "../../components/Button";
import { Nav } from "react-bootstrap";
import i18n from "i18next";
import { Interweave } from "interweave";

// images
import helmetIcon from "../../assets/icons/helmet.svg";
import armorIcon from "../../assets/icons/body-armor.svg";
import antisepticIcon from "../../assets/icons/antiseptic.svg";
import foodIcon from "../../assets/icons/food.svg";
import clothesIcon from "../../assets/icons/clothes.svg";
import { Tracking } from '../../helpers/tracking';

const Disaster = () => {
    const sliderRef = useRef(null);
    const screen = useScreenWidth();
    // const [isHovered, setIsHovered] = useState(false);
    const [currentIndex, setCurrentIndex] = useState(0);

    const slides = createSlides();

    const SliderArrow = ({ direction, index, className }) => (
        <div 
            className={`arrow ${className ?? ''} ${direction} ${(direction == 'prev' && index == 0) ? 'disabled' : (direction == 'next' && index == slides.length - 1) ? 'disabled' : ''}`}
            onClick={() => {
                if (direction == 'prev' && index !== 0) {
                    sliderRef.current.slickPrev();
                }
                else if (direction == 'next' && index !== slides.length - 1) {
                    sliderRef.current.slickNext();
                }
            }}
        >   
            <img src={Arrow} alt="" />
        </div>
    )

    const sliderOptions = {
        dots: false,
        infinite: true,
        slidesToShow: 1,
        slidesToScroll: 1,
        afterChange: (current, next) => {
            setCurrentIndex(current);
        },
        autoplay: true,
        autoplaySpeed: 3000,
    }

    // useEffect(() => {
    //     if (sliderRef?.current) {
    //         let sliderInterval = setInterval(() => {
    //             if (!isHovered) {
    //                 sliderRef.current.slickNext();
    //             }
    //         }, 3000);

    //         return () => clearInterval(sliderInterval)
    //     }
    // }, [sliderRef]);

    const onDonateClick = () => Tracking.scrollToDonate();

    return (
        <section className="app-disaster" id="disaster">
            <div className="container">
                <div className="row">
                    <div className="col-lg-7">
                        <div className="app-disaster-content">
                            <Tag color={'black'} text={i18n.t('homepage.disaster.tag')}/>
                            <h3>{i18n.t('homepage.disaster.text')}</h3>

                            <div className="app-disaster-content-needed">
                                <img src={foodIcon} alt="" />
                                <img src={clothesIcon} alt="" />
                                <img src={antisepticIcon} alt="" />
                                <img src={armorIcon} alt="" />
                                <img src={helmetIcon} alt="" />

                                <p>{i18n.t('homepage.disaster.needed')}</p>
                            </div>
                        </div>
                    </div>

                    <div className="col-lg-5">
                        <div 
                            className="app-disaster-img"
                            // onMouseEnter={() => setIsHovered(true)}
                            // onMouseLeave={() => setIsHovered(false)}
                        >

                            {screen == 'sm' && <SliderArrow className="mobile" direction={'prev'} index={currentIndex} />}
                            {screen == 'sm' && <SliderArrow className="mobile" direction={'next'} index={currentIndex} />}

                            <Slider 
                                className="app-disaster-img-wrap" {...sliderOptions} 
                                ref={sliderRef}>
                                {
                                    slides.map((item, index) => (
                                        <div                                             
                                            key={item.text} 
                                            className="app-disaster-img-item">
                                            
                                            <img src={item.img} alt="" />                                                                                       
                                        </div>
                                    ))
                                }
                            </Slider>

                            <div className="app-disaster-img-bottom">
                                <span className="app-disaster-img-bottom-text">{slides[currentIndex].text}</span>
                                
                                {screen !== 'sm' && <SliderArrow direction={'prev'} index={currentIndex} />}
                                <span className="app-disaster-img-bottom-current">{currentIndex + 1} / {slides.length}</span>
                                {screen !== 'sm' && <SliderArrow direction={'next'} index={currentIndex} />}
                            </div>
                        </div>
                    </div>
                </div>

                <div className="app-disaster-donate">
                    <Interweave className={i18n.language} containerTagName="p" content={i18n.t("homepage.disaster.donate.text")} />
                    
                    <Nav.Link href="#what-can" onClick={onDonateClick}>
                        <Button type="submit" className={'white'}>{i18n.t('general.donate')}</Button>
                    </Nav.Link>
                </div>
            </div>
        </section>
    );
}

export default Disaster;

function createSlides() {
    return [
        {
            img: disOne,
            text: "Border Ukraine-Moldova: refugees on the border with Moldova / Photo: Konstantin Liberov" 
        },
        {
            img: disTwo,
            text: "Mykolaiv, Ukraine: city lives without water supply / Photo: Konstantin Liberov"
        },
        {
            img: disThree,
            text: "Vinnitsa, Ukraine: Family is hiding in the dungeon from air strikes"
        },
        {
            img: disFour,
            text: "Vasylkiv, Ukraine: Girl with rescued cat / Photo: @uanimals.official"
        }
    ];
}
