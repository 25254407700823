import { useEffect, useState } from "react";
import cardIcon from "../../assets/icons/card.svg";
import clipboardIcon from "../../assets/icons/clipboard.svg";
import { Popover, OverlayTrigger } from "react-bootstrap";

import DatePicker from 'react-date-picker';

import Button from "../../components/Button";
import axios from "axios";
import { sendApi, sendApiDev, sendApiQA } from "../../config";

import i18n from "i18next";
import { getItem, setItem } from "../../helpers/cookie";

import successIcon from "../../assets/icons/check.svg";
import { Tracking } from '../../helpers/tracking';

const Form = () => {
    const clipboards = createClipboards();

    const [ show, setShow ] = useState(false);
    const [showForm, setShowForm] = useState(false);
    
    const [form, setForm] = useState({
        type: {
            title: 'Type',
            desc: 'type',
            value: getItem('form.type') ?? 'person'
        },
        name: {
            value: getItem('name') ?? '',
            title: 'Name, surname',
            desc: i18n.t(`form.popup.person.name`)
        }, 
        address: {
            value: getItem('address') ?? '',
            title: 'Address',
            desc: i18n.t(`form.popup.person.address`),
        }, 
        email: {
            value: (getItem('email') && /^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$/.test(getItem('email'))) ? getItem('email') : '',
            title: 'Email',
            desc: i18n.t(`form.popup.person.email`)
        }, 
        amount: {
            value: getItem('amount') ?? '',
            title: 'Donation amount',
            desc: i18n.t(`form.popup.person.amount`)
        }, 
        date: {
            value: getItem('date') ?? String(new Date()),
            title: 'Donation date',
            desc: i18n.t(`form.popup.person.date`)
        }
    });

    const [isDisabled, setIsDisabled] = useState(true);
    const [isSuccess, setIsSuccess] = useState(false);
    const [invalids, setInvalids] = useState([]);

    const formChange = (e) => {         
        setItem(e.target.name, e.target.value);

        if (!e.target.value.length) {
            if (!invalids.includes(e.target.name)) {
                setInvalids([...invalids, e.target.name]);
            }
        }
        else if ( e.target.name == 'email' && !/^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$/.test(e.target.value)) {
            if (!invalids.includes(e.target.name)) {
                setInvalids([...invalids, e.target.name]);
            }
        }
        else {
            setInvalids(prev => prev.filter(el => el !== e.target.name));
        }

        setForm({
            ...form,
            [e.target.name]: {...form[e.target.name], value: e.target.value},
        });
    }

    useEffect(() => {
        if (!showForm) {
            document.querySelector('html').style.overflowY = "auto";
            
            let successTimer = setTimeout(() => {
                setIsSuccess(false);

                return () => clearTimeout(successTimer);
            }, 1000);
        }
        else {
            document.querySelector('html').style.overflowY = "hidden";
        }
    }, [showForm]);
    
    useEffect(() => {
        let newForm = {...form};
        
        Object.keys(form).map(item => {
            if (item !== 'type') {
                newForm[item].desc = i18n.t(`form.popup.${form.type.value}.${item}`);
            }
        });

        setForm(newForm);
    }, [form.type]);

    useEffect(() => {
        let disabled = Object.keys(form).every(item => form[item].value?.length && invalids.length == 0);
        setIsDisabled(!disabled);
    }, [form, invalids]);

    const sendForm = async () => {
        Tracking.requestReceipt(form.amount.value);
        
        try {
            setIsDisabled(true);

            const { data } = await axios.post(`${sendApiQA}`, {
                ...form,
                to: 'bdm1.perfectorium@gmail.com, info@bless-ukraine.com',
                subject: 'Bless Ukraine'
            });

            if (data.status == 'success') {
                setIsSuccess(true);

            }
            setIsDisabled(false);
        }
        catch(e) {
            console.error(e);
        }
    }

    useEffect(() => {
        if (show !== false) {
            let timer = setTimeout(() => setShow(false), 500);
            return () => clearTimeout(timer)
        }
    }, [show]);

    const callPopover = (
        <Popover className="app-popover">
            <span>{i18n.t('form.main.copy')}</span>
        </Popover>
    );

    const onCopyClick = (item, index) => async () => {
        Tracking.copyDonationField(item.name);

        try {
            await navigator.clipboard.writeText(item.value);
            setShow(index);
        } catch (error) {
            console.error(error);
        }
    };

    const onRequestReceiptClick = () => {
        Tracking.openRequestReceipt();

        setShowForm(!showForm);
    };

    return (
        <section className="app-form">
            <div className="row">
                <div className="col-xl-4">
                    <div className="app-form-sidebar">
                        {/* <div className="app-form-sidebar-indicators">
                            <div className="app-form-sidebar-indicators-item active"></div>
                            <div className="app-form-sidebar-indicators-item"></div>
                        </div> */}

                        <div className="app-form-sidebar-card">
                            <div className="app-form-sidebar-card-icon">
                                <img src={cardIcon} alt="" />
                            </div>
                            <h4>{i18n.t('form.sidebar.donation')}</h4>
                        </div>

                        <p>{i18n.t('form.sidebar.description')}</p>
                        <Button type={'submit'} className="disabled" link='/'>{i18n.t('form.sidebar.btn')}</Button>
                    </div>
                </div>

                <div className="col-xl-8">
                    <div className="app-form-main">
                        <div className="app-form-main-clipboards">
                            {
                                clipboards.map((item, index) => (
                                    <OverlayTrigger 
                                        show={(typeof show == 'number' && show == index) ? true : false}
                                        key={item.value} 
                                        trigger="click" 
                                        placement="bottom" 
                                        overlay={callPopover}
                                        >
                                        
                                        <div 
                                            onClick={onCopyClick(item, index)}
                                            className="app-form-main-clipboards-item">

                                            <span>{item.title.toUpperCase()}</span>
                                            <div className="app-form-main-clipboards-item-window">
                                                <p>{item.value}</p>
                                                <img src={clipboardIcon} alt="" />
                                            </div>
                                        </div>
                                    </OverlayTrigger>
                                ))
                            }
                        </div>

                        <div className="app-form-main-cta">
                            <p>{i18n.t('form.main.required')}</p>
                            <Button type="submit" onClick={onRequestReceiptClick}>{i18n.t('form.main.btn')}</Button>
                        </div>
                    </div>
                </div>
            </div>

            <div className={`app-form-popup ${showForm ? 'visible' : ''}`}>
                <div className="app-form-popup-bcg" onClick={() => setShowForm(false)}></div>
                
                <div className={`app-form-popup-wrap`}>
                    <div className="app-form-popup-wrap-head">
                        <h4>{i18n.t("form.popup.title")}</h4>
                        <div className="app-form-popup-wrap-head-close" onClick={() => setShowForm(false)}></div>
                    </div>

                    {
                        !isSuccess ?
                            <div className="app-form-popup-wrap-body">
                                <div className="app-form-popup-wrap-trigger">
                                    <div className={`app-form-popup-wrap-trigger-item ${form.type.value == 'person' ? 'current' : ''}`} onClick={() => setForm({...form, type: {...form.type, value: 'person'}})}>
                                        <span>Natural person</span>
                                    </div>
                                    <div className={`app-form-popup-wrap-trigger-item ${form.type.value == 'company' ? 'current' : ''}`} onClick={() => setForm({...form, type: {...form.type, value:'company'}})}>
                                        <span>Company</span>
                                    </div>
                                </div>

                                {
                                    Object.keys(form).map((key, index) => (
                                        (key !== 'type' ?
                                            <div className={`app-form-popup-wrap-input ${key}`} key={key}>
                                                    <span>{form[key].desc}</span>
                                                    {
                                                        key == 'date' ?
                                                            <DatePicker 
                                                                locale={i18n.language == 'en' ? 'en' : i18n.language == 'ua' ? 'uk' : i18n.language == 'ge' ? 'de' : 'en'} 
                                                                className="app-form-popup-wrap-input-date" 
                                                                onChange={(e) => setForm({...form, date: {...form.date, value: e ? String(e) : String(new Date())}})} 
                                                                key={key} value={new Date(form.date.value)} />
                                                        : 
                                                            <input 
                                                                required 
                                                                onBlur={(e) => {
                                                                    if (invalids.includes(key)) {
                                                                        if (key == 'email' && e.target.value.length) {
                                                                            e.target.parentElement.classList.add('invalid');
                                                                        }
                                                                        else if (!e.target.value.length) {
                                                                            e.target.parentElement.classList.add('required');
                                                                        }
                                                                    }
                                                                    else {
                                                                        e.target.parentElement.classList.remove('invalid');
                                                                        e.target.parentElement.classList.remove('required');
                                                                    }
                                                                }} 
                                                                type={key == 'email' ? 'email' : "text"} 
                                                                name={key} 
                                                                value={form[key].value} 
                                                                onChange={formChange} 
                                                            />
                                                    }
                                            </div>
                                        : null
                                        )
                                    ))
                                }                        
                                <div className="app-form-popup-wrap-send">
                                    <span>{i18n.t("form.popup.required")}</span>
                                    <Button type={'submit'} disabled={isDisabled} onClick={sendForm}>{i18n.t("form.popup.btn")}</Button>
                                </div>
                            </div> 
                        :
                            <div className="app-form-popup-wrap-success">
                                <div className="app-form-popup-wrap-success-icon">
                                    <img src={successIcon} alt="" />
                                </div>
                                <h4>{i18n.t("form.popup.success.title")}</h4>
                                <p>{i18n.t("form.popup.success.text")}</p>
                            </div>
                    }    
                </div>            
            </div>                
        </section>
    )
}

export default Form;

function createClipboards() {
    return [
        {
            title: i18n.t('form.main.fields.destination'),
            value: 'Bless Ukraine',
            name: 'destination',
        },
        {
            title: i18n.t('form.main.fields.iban'),
            value: 'CH62 0900 0000 1593 9641 4',
            name: 'iban',
        },
        {
            title: i18n.t('form.main.fields.bic'),
            value: 'POFICHBEXXX',
            name: 'bic',
        },
        {
            title: i18n.t('form.main.fields.purpose'),
            value: i18n.t('form.main.fields.donation'),
            name: 'purpose',
        },
    ];
}
