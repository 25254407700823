import { Nav } from "react-bootstrap";
import Button from "../../components/Button";
import * as i18n from "i18next";
import Photo from "../../components/Photo";

import heroImgLg from '../../assets/img/rest/webp/hero-1440w.webp';
import heroImgMd from '../../assets/img/rest/webp/hero-992w.webp';
import heroImgSm from '../../assets/img/rest/webp/hero-688w.webp';

const Hero = () => {
    return (
        <div className="app-hero" id="hero">
            <div className="container">
                <h1>{i18n.t('homepage.hero.title')}</h1>
                <p>{i18n.t('homepage.hero.subtitle')}</p>
            </div>

            <Nav.Link href="#what-can" className="app-hero-link">
                <Button type="submit">
                    {i18n.t('general.donate')}
                </Button>
            </Nav.Link>    

            <div className="app-hero-img">
                <Photo
                    src={heroImgLg} 
                    srcset={`${heroImgSm} 688w,
                             ${heroImgMd} 992w,
                             ${heroImgLg} 1440w`}
                    sizes={`(max-width: 688px) 688w,
                            (max-width: 992px) 992w,
                            1440w`}
                    parrallax={true} 
                />
            </div>
        </div>
    )
}

export default Hero;
