import houseImg from "../../assets/icons/house.svg";
import deathImg from "../../assets/icons/death.svg";
import runnerImg from "../../assets/icons/runner.svg";
import i18n from "i18next"; 

const Result = () => {
    const cards = createCards();

    return (
        <section className="app-result">
            <div className="container">
                <h2>{i18n.t('homepage.result.title')}</h2>
                <p>{i18n.t('homepage.result.subtitle')}</p>
            
                <div className="app-result-cards">
                    {cards.map(item => (
                        <div
                            style={{ backgroundColor: item.bcg, border: item.border ? `2px solid ${item.border}`: 'none' }} 
                            key={item.title} 
                            className={`app-result-cards-item ${i18n.language}`}>
                            
                            <div className="app-result-cards-item-icon">
                                <img src={item.icon} alt="" />
                            </div>

                            <div className="app-result-cards-item-text">
                                <h4 style={{color: item.textColor ?? '#000'}}>{item.title}</h4>
                                <p style={{color: item.textColor ?? '#000'}}>{item.text}</p>
                            </div>
                        </div>
                    ))}
                </div>
            </div>
        </section>
    );
}

export default Result;

function createCards() {
    return [
        {
            icon: runnerImg,
            bcg: '#AEAEAE',
            title: i18n.t('homepage.result.cards.exodus.title'),
            text: i18n.t('homepage.result.cards.exodus.text')
        },
        {
            icon: houseImg,
            bcg: '#0C0C0C',
            border: '#fff',
            title: i18n.t('homepage.result.cards.damage.title'),
            textColor: 'white',
            text: i18n.t('homepage.result.cards.damage.text')
        },
        {
            icon: deathImg,
            bcg: '#BC322A',
            title: i18n.t('homepage.result.cards.deaths.title'),
            text: i18n.t('homepage.result.cards.deaths.text')
        },
    ];
}
