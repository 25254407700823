import { useState, useEffect, useRef } from "react";
import i18n from "i18next";
import { available_languages } from "../../i18n";
import { setItem } from "../../helpers/cookie";
import { Tracking } from '../../helpers/tracking';

export const LanguageSwitcher = ({ className }) => {
    const [active, setActive] = useState(false);
    const switcherRef = useRef(null)

    useEffect(() => {
        document.addEventListener('click', e => {
            if (!e.path.includes(switcherRef.current)) {
                setActive(false);
            }
        });
    }, []);

    const changeLanguage = (language) => {
        Tracking.setLanguage(language);
        
        i18n.changeLanguage(language);
        setItem('i18nLanguage', language);
        setActive(false);

        setTimeout(() => {
            document.location.reload(true);
        }, 1000);
    };

    return (
        <div className={`app-language-switcher ${className}`} ref={switcherRef}>
            <div className={`app-language-switcher-current ${active ? 'active': ''}`} onClick={() => setActive(!active)}>
                <span>{ i18n.language }</span>
                <i></i>
            </div>
            <ul className={`app-language-switcher-menu ${active ? 'active' : ''}`}>
                {
                    available_languages.map(language => (
                        (language !== i18n.language) ?
                            <li key={language} onClick={() => changeLanguage(language)} ><span>{language}</span></li>
                        : null
                    ))
                }
            </ul>
        </div>
    )
}

export default LanguageSwitcher;
