import { Header } from "../../sections/Header";
import Footer from "../../sections/Footer";
import Contact from "../../sections/Contact";
import i18n from "i18next";
import { Interweave } from "interweave";

const Privacy = () => {
    return (
        <div className="app-privacy">
            <Header theme="light"/>
            
            <Interweave containerTagName="h1" content={i18n.t('privacy.title')} />
            
            <section className="app-privacy-content">
                <div className="container">
                    <div className="app-privacy-block">
                        <h2>{i18n.t('privacy.content.protection.title')}</h2>
                        
                        <h3>{i18n.t('privacy.content.protection.general.title')}</h3>
                        <Interweave containerTagName="p" content={i18n.t('privacy.content.protection.general.text')} />
                        
                        <h3>{i18n.t('privacy.content.protection.collection.title')}</h3>
                        <h4>{i18n.t('privacy.content.protection.collection.subtitle')}</h4>
                        <Interweave containerTagName="p" content={i18n.t('privacy.content.protection.collection.text')} />
                        
                        <h4>{i18n.t('privacy.content.protection.how_collect.subtitle')}</h4>
                        <Interweave containerTagName="p" content={i18n.t('privacy.content.protection.how_collect.text')} />
                        
                        <h4>{i18n.t('privacy.content.protection.what_use.subtitle')}</h4>
                        <Interweave containerTagName="p" content={i18n.t('privacy.content.protection.what_use.text')} />
                        
                        <h4>{i18n.t('privacy.content.protection.regarding.subtitle')}</h4>
                        {
                            Object.keys(i18n.t('privacy.content.protection.regarding.text', {returnObjects: true})).map(item => (
                                <Interweave key={item} containerTagName="p" content={i18n.t('privacy.content.protection.regarding.text', {returnObjects: true})[item]} />
                            ))
                        }

                    </div>

                    <div className="app-privacy-block">
                        <h2>{i18n.t('privacy.content.hosting.title')}</h2>
                        
                        <h3>{i18n.t('privacy.content.hosting.hetzner.title')}</h3>
                        {
                            Object.keys(i18n.t('privacy.content.hosting.hetzner.text', {returnObjects: true})).map(item => (
                                <Interweave key={item} containerTagName="p" content={i18n.t('privacy.content.hosting.hetzner.text', {returnObjects: true})[item]} />
                            ))
                        }
                    </div>

                    <div className="app-privacy-block">
                        <h2>{i18n.t('privacy.content.mandatory.title')}</h2>
                        <h3>{i18n.t('privacy.content.mandatory.privacy.title')}</h3>
                        {
                            Object.keys(i18n.t('privacy.content.mandatory.privacy.text', {returnObjects: true})).map(item => (
                                <Interweave key={item} containerTagName="p" content={i18n.t('privacy.content.mandatory.privacy.text', {returnObjects: true})[item]} />
                            ))
                        }

                        <h3>{i18n.t('privacy.content.mandatory.note.title')}</h3>
                        <p>{i18n.t('privacy.content.mandatory.note.text', {returnObjects: true})[0]}</p>
                        <Interweave containerTagName="p" content={i18n.t('privacy.content.mandatory.note.address')} />
                            
                        <ul>
                            <li>
                                <span>{i18n.t('imprint.contact.phone')}: </span>
                                <a href="tel:+49 157 325 06 232" target="_blank">+49 157 325 06 232</a>
                            </li>
                            <li>
                                <span>{i18n.t('imprint.contact.phone')}: </span>
                                <a href="tel:+49 151 700 84 760" target="_blank">+49 151 700 84 760</a>
                            </li>
                            <li>
                                <span>{i18n.t('imprint.contact.mail')}: </span>
                                <a href="mailto:info@bless-ukraine.com" target="_blank">info@bless-ukraine.com</a>
                            </li>
                        </ul>
                        <Interweave containerTagName="p" content={i18n.t('privacy.content.mandatory.note.text', {returnObjects: true})[1]} />
                        
                        <h3>{i18n.t('privacy.content.mandatory.storage.title')}</h3>
                        {
                            Object.keys(i18n.t('privacy.content.mandatory.storage.text', {returnObjects: true})).map(item => (
                                <Interweave key={item} containerTagName="p" content={i18n.t('privacy.content.mandatory.storage.text', {returnObjects: true})[item]} />
                            ))
                        }
                        
                        <h3>{i18n.t('privacy.content.mandatory.processing.title')}</h3>
                        {
                            Object.keys(i18n.t('privacy.content.mandatory.processing.text', {returnObjects: true})).map(item => (
                                <Interweave key={item} containerTagName="p" content={i18n.t('privacy.content.mandatory.processing.text', {returnObjects: true})[item]} />
                            ))
                        }
                        
                        <h3>{i18n.t('privacy.content.mandatory.revocation.title')}</h3>
                        {
                            Object.keys(i18n.t('privacy.content.mandatory.revocation.text', {returnObjects: true})).map(item => (
                                <Interweave key={item} containerTagName="p" content={i18n.t('privacy.content.mandatory.revocation.text', {returnObjects: true})[item]} />
                            ))
                        }

                        <h3>{i18n.t('privacy.content.mandatory.cases.title')}</h3>
                        {
                            Object.keys(i18n.t('privacy.content.mandatory.cases.text', {returnObjects: true})).map(item => (
                                <Interweave key={item} containerTagName="p" content={i18n.t('privacy.content.mandatory.cases.text', {returnObjects: true})[item]} />
                            ))
                        }
                        
                        <h3>{i18n.t('privacy.content.mandatory.competent.title')}</h3>
                        {
                            Object.keys(i18n.t('privacy.content.mandatory.competent.text', {returnObjects: true})).map(item => (
                                <Interweave key={item} containerTagName="p" content={i18n.t('privacy.content.mandatory.competent.text', {returnObjects: true})[item]} />
                            ))
                        }
                        
                        <h3>{i18n.t('privacy.content.mandatory.portability.title')}</h3>
                        {
                            Object.keys(i18n.t('privacy.content.mandatory.portability.text', {returnObjects: true})).map(item => (
                                <Interweave key={item} containerTagName="p" content={i18n.t('privacy.content.mandatory.portability.text', {returnObjects: true})[item]} />
                            ))
                        }
                        
                        <h3>{i18n.t('privacy.content.mandatory.encryption.title')}</h3>
                        {
                            Object.keys(i18n.t('privacy.content.mandatory.encryption.text', {returnObjects: true})).map(item => (
                                <Interweave key={item} containerTagName="p" content={i18n.t('privacy.content.mandatory.encryption.text', {returnObjects: true})[item]} />
                            ))
                        }

                        <h3>{i18n.t('privacy.content.mandatory.correction.title')}</h3>
                        {
                            Object.keys(i18n.t('privacy.content.mandatory.correction.text', {returnObjects: true})).map(item => (
                                <Interweave key={item} containerTagName="p" content={i18n.t('privacy.content.mandatory.correction.text', {returnObjects: true})[item]} />
                            ))
                        }

                        <h3>{i18n.t('privacy.content.mandatory.restriction.title')}</h3>
                        <p>{i18n.t('privacy.content.mandatory.restriction.text', {returnObjects: true})[0]}</p>
                        
                        <ul>
                            {
                                Object.keys(i18n.t('privacy.content.mandatory.restriction.list', {returnObjects: true})).map(item => (
                                    <li key={item}>
                                        <span>{i18n.t('privacy.content.mandatory.restriction.list', {returnObjects: true})[item]}</span>
                                    </li>
                                ))
                            }
                        </ul>
                        <p>{i18n.t('privacy.content.mandatory.restriction.text', {returnObjects: true})[1]}</p>
                    </div>

                    <div className="app-privacy-block">
                        <h2>{i18n.t('privacy.content.collection.title')}</h2>
                        <h3>{i18n.t('privacy.content.collection.contact.title')}</h3>
                        {
                            Object.keys(i18n.t('privacy.content.collection.contact.text', {returnObjects: true})).map(item => (
                                <Interweave key={item} containerTagName="p" content={i18n.t('privacy.content.collection.contact.text', {returnObjects: true})[item]} />
                            ))
                        }
                        
                        <h3>{i18n.t('privacy.content.collection.inquiry.title')}</h3>
                        {
                            Object.keys(i18n.t('privacy.content.collection.inquiry.text', {returnObjects: true})).map(item => (
                                <Interweave key={item} containerTagName="p" content={i18n.t('privacy.content.collection.inquiry.text', {returnObjects: true})[item]} />
                            ))
                        }
                    </div>
                </div>
            </section>

            <Contact />
            <Footer />
        </div>
    )
}

export default Privacy;
