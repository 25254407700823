export const trackEvent = (name, params) => {
    // eslint-disable-next-line no-undef
    gtag('event', name, params);
};

export const Tracking = {
    visitInstagram() {
        trackEvent('visit_instagram');
    },
    scrollToDonate() {
        trackEvent('scroll_to_donate');
    },
    copyDonationField(name) {
        trackEvent('copy_donation_field', { field_name: name });
    },
    openRequestReceipt() {
        trackEvent('open_request_receipt');
    },
    requestReceipt(amount) {
        trackEvent('request_receipt', { donation_amount: amount });
    },
    visitTrustfulResource(url) {
        trackEvent('visit_trustful_resource', { resource: url });
    },
    callUs(number) {
        trackEvent('call_us', { phone_number: number });
    },
    emailUs(email) {
        trackEvent('email_us', { email });
    },
    setLanguage(code) {
        trackEvent('set_language', { language_code: code });
    },
};
