import React, { useEffect, useRef } from "react"; 
import i18n from "i18next";
import { Header } from "../../sections/Header";
import Hero from "../../sections/Hero";
import WarText from "../../sections/WarText";
import Slick from "../../sections/Slider";
import Result from "../../sections/Result";
import Disaster from "../../sections/Disaster";
import Organisation from "../../sections/Organisation";
import Principles from "../../sections/Principles";
import Activities from "../../sections/Activities";
import Photo from "../../components/Photo";
import WhatCan from "../../sections/WhatCan";
import Contact from "../../sections/Contact";
import Footer from "../../sections/Footer";
import photoOneLg from '../../assets/img/rest/webp/photo-1-1440w.webp';
import photoOneMd from '../../assets/img/rest/webp/photo-1-992w.webp';
import photoOneSm from '../../assets/img/rest/webp/photo-1-688w.webp';
import photoSecondLg from '../../assets/img/rest/webp/stand-1440w.webp';
import photoSecondMd from '../../assets/img/rest/webp/stand-992w.webp';
import photoSecondSm from '../../assets/img/rest/webp/stand-688w.webp';
import { useIsVisible } from '../../hooks/use-is-visible';
import { useHeaderContext } from '../../hooks/use-header-theme';

const Home = () => {
    const { enableLightTheme, enableDarkTheme } = useHeaderContext();

    const triggerRef = useRef(null);
    const lightThemeSectionsVisible = useIsVisible(triggerRef);

    useEffect(() => {
        if (lightThemeSectionsVisible) {
            enableLightTheme();
        } else {
            enableDarkTheme();
        }
    }, [lightThemeSectionsVisible, enableLightTheme, enableDarkTheme]);

    return (
        <div className="app-home">            
            <Header />
            <Hero/>
            <WarText/>
            <Slick/>
            <Result/>
            <Photo
                srcset={`${photoOneSm} 688w,
                         ${photoOneMd} 992w,
                         ${photoOneLg} 1440w`}
                sizes={`(max-width: 688px) 688w,
                        (max-width: 992px) 992w,
                        1440w`} 
                parrallax={true} 
                by="photo by Konstantin Liberov"
            />

            <div className="trigger-header" ref={triggerRef}>
                <Disaster/>
                <Organisation/>
                <Principles/>
                <Activities/>
                <WhatCan/>
                <Photo 
                    parrallax={true}
                    srcset={`${photoSecondSm} 688w,
                             ${photoSecondMd} 992w,
                             ${photoSecondLg} 1440w`}
                    sizes={`(max-width: 688px) 688w,
                            (max-width: 992px) 992w,
                            1440w`} 
                >    
                    <div className="content">
                        <h2>{i18n.t('homepage.stand.title')}</h2>
                        <p>{i18n.t('homepage.stand.text')}</p>
                    </div>
                </Photo>
                <Contact/>
                <Footer />
            </div>
        </div>
    )
}

export default Home;
