import i18n from "i18next";
import { initReactI18next } from "react-i18next";

import {en, ua, de} from "./translations";
import { getItem } from "../helpers/cookie";

export const available_languages = ['en', 'ua', 'de'];

export default i18n
    .use(initReactI18next)
    .init({
        resources: {
            en: {
                translations: en
            },
            ua: {
                translations: ua
            },
            de: {
                translations: de
            },
        },
        lng: getItem('i18nLanguage') || 'en',
        lang: getItem('i18nLanguage') || 'en',
        fallbackLng: available_languages,
        ns: ['translations'],
        defaultNS: 'translations',
        interpolation: {
            escapeValue: false,
            formatSeparator: '.'
        },
        react: {
            wait: true
        }
    })