import Tag from "../../components/Tag";
import crestImg from "../../assets/img/rest/webp/crest.webp";
import i18n from "i18next";
import { Interweave } from "interweave";

const Organisation = () => (
    <section className="app-organisation" id="about">
        <div className="container">
            <div className="app-organisation-wrap">
                <Tag text={i18n.t('homepage.organisation.tag')} color="black"/>
                <h2>{i18n.t('homepage.organisation.title')}</h2>
                {/* <p>We force our efforts to help solve the problem of humanitarian catastrophe in the heart of Europe. As of now, we act in cooperation and under the umbrella of <Link to="/">JunOst Bayern</Link> which is based in Munich and is existing for over 13 years.</p> */}
                <Interweave containerTagName="p" content={i18n.t('homepage.organisation.text')}/>
                <img src={crestImg} alt="" />
            </div>
        </div>
    </section>
);

export default Organisation;
