export const classnames = (...args) => 
    args
        .reduce(
            (result, arg) =>
                typeof arg === 'string'
                    ? `${result} ${arg}`
                    : `${result} ${classnamesObjectToString(arg)}`,
            '',
        )
        .trim();

function classnamesObjectToString(classNameToCondition) {
    return Object
        .entries(classNameToCondition)
        .reduce(
            (result, [className, condition]) => condition ? `${result} ${className}` : result,
            '',
        )
        .trim();
}
