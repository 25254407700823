const labels = {
    general: {
        donate: 'Donate'
    },
    header: {
        menu: {
            war: 'War',
            disaster: 'Humanitarian disaster',
            about: 'About Us',
            activities: 'Our activities',
            contacts: 'Contacts'            
        }
    },
    homepage: {
        hero: {
            title: 'Defending Ukraine together',
            subtitle: 'We force our efforts to help solve the problem of humanitarian catastrophe'
        },
        war: {
            tag: 'WAR',
            text: 'Upon unlawful annexation of Crimea from Ukraine and invasion of unmarked Russian military vehicles into the Donbas region in 2014, eight years later on <span>24.02.2022</span> Russian Federation invaded Ukrainian borders and started the full-scale war against Ukrainian citizens.'
        },
        slider: {
            mariupol: {
                title: 'Mariupol',
                region: 'Donetsk region'
            },
            bucha: {
                title: 'Bucha',
                region: 'Kyiv region'
            },
            irpin: {
                title: 'Irpin',
                region: 'Kyiv region'
            },
            kyiv: {
                title: 'Kyiv',
                region: 'Kyiv region'
            },
            kharkiv: {
                title: 'Kharkiv',
                region: 'Kharkiv region'
            },
            odesa: {
                title: 'Odesa',
                region: 'Odesa region'
            },
            chernihiv: {
                title: 'Chernihiv',
                region: 'Chernihiv region'
            },
            mykolaiv: {
                title: 'Mykolaiv',
                region: 'Mykolaiv region'
            },
        },
        result: {
            title: 'As a result:',
            subtitle: 'From February 24th hundreds of Ukrainian cities were destroyed and millions of lifes were broken.',
            cards: {
                exodus: {
                    title: 'Mass exodus',
                    text: 'People were forced to leave their homes and seek refuge in other EU countries: Poland, Lithuania, Germany, and others.'
                },
                damage: {
                    title: 'Huge damage',
                    text: 'As a result of massive racist bombings, a large number of houses were destroyed or damaged. Recovery may take several years.'
                },
                deaths: {
                    title: 'Thousands of deaths',
                    text: "The war brutally and cynically claimed the lives of many Ukrainians, defenders, rescuers, and ordinary women, children, and retirees."
                }
            }
        },
        disaster: {
            tag: 'HUMANITARIAN DISASTER',
            text: 'There is an acute need for medical and hygiene supplies, food, clothing, protective equipment and much more.',
            needed: '– needed',
            donate: {
                text: 'Despite the overall efforts, all these necessities stay <span>not fully covered.</span> Don’t stay aside, help our people.'
            }
        },
        organisation: {
            tag: 'ABOUT US',
            title: 'We are a non-profit organisation Bless Ukraine',
            text: 'We force our efforts to help solve the problem of humanitarian catastrophe in the heart of Europe. We act in cooperation with <a href="https://www.junost-bayern.de/junostbayern/" target="_blank">JunOst Bayern</a> which is based in Munich and is existing for over 13 years.',
        },
        principles: {
            title: 'Our main principles are: ',
            items: {
                fast: 'Responding fast to the challenges of humanitarian needs',
                avoiding: 'Avoiding unnecessary bureaucratic procedures',
                supporting: 'Supporting directly local recepients',
                costs: 'Excluding costs for fancy marketing',
                prioritization: "Distributing goods according to regions' needs (prioritization)"
            }
        },
        activities: {
            tag: 'OUR ACTIVITIES',
            cards: {
                essentials: {
                    title: 'Major essentials',
                    text: ['Purchase and delivery of food', 'Hygiene supplies', 'Clothing', 'Blankets, mattresses etc.']
                },
                info: {
                    title: 'Info assistance',
                    text: ['Consulting for refugees regarding their needs and rights in Europe','Search for missing people']
                },
                medical: {
                    title: 'Medical Procurement',
                    text: ['Delivery of first aid kits, bandages, etc. in war zones', 'Procurement of first-aid vehicles']
                },
                protective: {
                    title: 'Protective equipment',
                    text: ['Supply with protective vests and helmets for paramedics volunteers in the frontline and other civil citizens']
                },
                pet: {
                    title: 'Pet care',
                    text: ['Delivery of pet food and  necessary veterinary medicines', 'Enforcement of animal protection laws', 'Finding new domicile for abandoned pets']
                },
                transport: {
                    title: 'Transport services',
                    text: ['Help in transporting sick and injured people to a safe environment']
                },
                political: {
                    title: 'Political engagement',
                    text: ['Creation and promotion of petitions concerning human rights']
                },
                reconstructions: {
                    title: 'Reconstruction works',
                    text: ['Support in cleaning up building rubble', 'Assistance in rescuing people from the rubble']
                }
            }
        },
        what_can: {
            tag: "DONATION",
            title: "What can you do",
            list: {
                first: "Support Ukraine by making a financial contribution to our fund. Every donation is important.",
                second: "Read trustful resources."
            }
        },
        news: {
            war: {
                title: "War in Ukraine",
                text: "The information is verified by the Ministry of Foreign Affairs of Ukraine."
            },
            with: {
                title: "With Ukraine",
                text: "Volunteer news organization."
            },
            president: {
                title: "President of Ukraine",
                text: "The Presidential Office of Ukraine."
            }
        },
        stand: {
            title: "Stand with Ukraine",
            text: "Thank you world"
        },
        contact: {
            description: "Contact us - we are here to help you",
            email_us: "Email us"
        }
    },
    // privacy: {
    //     title: 'Privacy Policy',
    //     content: {
    //         subtitle: "Information we collect",
    //         text: 'The type of personal information we collect depends on how you are specifically interacting with us and which Services you are using. Generally, we collect the following categories of personal information:',
    //         personal_list: {
    //             1: 'Contact Information, such as name, alias, address, phone number, social media user ID, email address, and similar contact data.',
    //             2: 'Organization and Contacts Information, such as your employer or organizations that you are a member of, information about your colleagues or those within your organization, your status with an organization, and similar data.',
    //             3: 'Account Information, such as security-related information (including user names and passwords, authentication methods, and roles), service-related information (including purchase history and account profiles), billing-related information (including payment, shipping, and billing information), and similar data.',
    //             4: 'User Content, such as content of communications, suggestions, questions, comments, feedback, and other information you send to us, that you provide to us when you contact us, or that you post on our Services (including information in alerts, folders, notes, and shares of content), and similar data.',
    //             5: 'Device & Browser Information, such as network and connection information (including Internet Service Provider (ISP) and Internet Protocol (IP) addresses), device and browser identifiers and information (including device, application, or browser type, version, plug-in type and version, operating system, user agent, language and time zone settings, and other technical information), advertising identifiers, cookie identifiers and information, and similar data.'
    //         }
    //     }
    // },
    // imprint: {
    //     title: 'Imprint',
    //     content: {
    //         subtitle: 'Information according to &sect; 5 TMG',
    //         text: 'Association Bless Ukraine<br />c/o MJP Partners AG<br />Bahnhofstrasse 20<br />6300 Zug',
    //         representative: {
    //             title: 'Represented by:',
    //             text: ['Karyna Gornishevska', 'Daria Tiurina']
    //         },
    //     },
    //     contact: {
    //         title: 'Contact',
    //         phone: 'Phone',
    //         mail: 'E-Mail'
    //     },
    //     accept: {
    //         title: 'Consumer dispute resolution/universal arbitration board',
    //         text: 'We are not willing or obliged to participate in dispute settlement procedures before a consumer arbitration board.'
    //     }
    // },

    form: {
        sidebar: {
            donation: "Donation by card",
            description: "* We are working under convenient direct donation system right now.",
            btn: "Soon available"
        },
        main: {
            fields: {
                destination: 'Recipient',
                iban: "IBAN",
                bic: "BIC",
                purpose: "Purpose",
                donation: 'Donation',
            },
            copy: "Copied !",
            required: "* If required, we can issue a donation receipt so that you can deduct it from your taxes. In order to do so, please send us an E-Mail request.",
            btn: "Request a receipt",
        },
        popup: {
            title: "Receipt request",
            person: {
                title: "Natural person",
                name: "NAME, SURNAME*",
                address: "ADDRESS*",
                email: "EMAIL*",
                amount: "DONATION AMOUNT*",
                date: "DONATION DATE*"
            },
            company: {
                title: "Company",
                name: "COMPANY NAME*",
                address: "COMPANY ADDRESS*",
                email: "EMAIL*",
                amount: "DONATION AMOUNT*",
                date: "DONATION DATE*"
            },
            required: "* REQUIRED FIELDS",
            btn: "Send request",
            success: {
                title: "Success",
                text: "Your form was successfully submitted !"
            }
        }
    },
    cookies: {
        text: 'This website uses cookies to ensure you get the best experience on our website.',
        link: "Cookies policy"
    },
    footer: {
        privacy: "Privacy policy",
        insta: "Instagram",
        facebook: "Facebook",
        photo: "Photo by ",
        design: "Designed by ",
        developed: "Developed by ",
        imprint: "Imprint",
    }
};

export default labels;
